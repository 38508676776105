import config from '../../../config'

export const fetchImsData = imageId => {
  const { images_api_url: apiUrl, images_api_app_id: appId, images_api_app_key: appKey } = config

  // Create url to fetch image data from images-api-v2.
  const imsDataUrl = `${apiUrl}presentation_data/${imageId}.json?app_id=${appId}&app_key=${appKey}`

  return fetch(imsDataUrl).then(response => {
    if (response.ok && response.headers.get('content-type').includes('application/json')) {
      return response.json()
    }

    // Fetch failed, reset state to not show the previous image.
    throw new Error(`Failed fetching IMS image with id ${imageId}`)
  })
}

const getCropCoordinates = ({ aspect, imsData }) => {
  if (!aspect) {
    return ''
  }

  // Make sure the image has crops for the selected asepct ratio.
  const crop = imsData.crops.find(data => data.name === aspect)
  if (!crop) {
    return ''
  }

  const { height, width, x, y } = crop.coordinates
  return `w_${width},h_${height},c_crop,x_${x},y_${y}/`
}

const getDpr = () => {
  const dpr1 = 'dpr_1.0'
  try {
    if (window.devicePixelRatio > 1) {
      return 'dpr_2.0'
    }
    return dpr1
  } catch (_) {
    return dpr1
  }
}

export const createImsImageUrl = ({ aspect, imsData }) => {
  const { images_cdn_url: imagesApiUrl } = config
  const { public_id: publicId, version } = imsData
  const crop = getCropCoordinates({ aspect, imsData })
  const dpr = getDpr()
  const parameters = `w_600,c_fill,${dpr}`

  return `${imagesApiUrl}/${crop}${parameters}/v${version}/${publicId}.jpg`
}
