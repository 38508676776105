import React from 'react'
import Notification from '../Notification/Notification'

const onSaveClosedTimestamp = () => {
  localStorage.setItem('choiceAdClosedAt', new Date().toISOString())
}

const isWeekPassed = () => {
  const week = 1000 * 60 * 60 * 24 * 7
  const closedAt = localStorage.getItem('choiceAdClosedAt')
  if (!closedAt) {
    return true
  }
  return new Date().getTime() - new Date(closedAt).getTime() > week
}

const ChoiceNotificationAd = () => {
  const [isClosed, setIsClosed] = React.useState(false)
  const onClose = () => {
    onSaveClosedTimestamp()
    setIsClosed(true)
  }
  if (!isWeekPassed() || isClosed) {
    return null
  }
  return (
    <div style={{ maxWidth: '40em', margin: '0 auto' }}>
      <Notification isMultiline onClose={onClose} title="Psst!" variant="primary">
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <div>
            Did you know that creating quizes is much easier with{' '}
            <a
              href="https://interaction.yle.fi/admin/choice"
              rel="noreferrer noopener"
              style={{ fontWeight: 600, color: 'inherit' }}
              target="_blank"
            >
              Choice
            </a>
            ?
          </div>
        </div>
      </Notification>
    </div>
  )
}

export default ChoiceNotificationAd
