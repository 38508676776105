import React, { useEffect, useRef, useState } from 'react'
import useYdsTheme from '../../../hooks/useYdsTheme/useYdsTheme'

// [CLIP id=1-4530023|autoplay=false]

const YleMediaPlayer = props => {
  const { ylePlayer, yleTehtavaApp } = window
  const { autoplay, id, playerLang = 'fin' } = props

  const playerEl = useRef()
  const player = useRef()

  const [renderedId, setRenderedId] = useState(undefined)
  const [rendering, setRendering] = useState(false)

  const theme = useYdsTheme()

  const videoProperties = {
    autoplay: autoplay === 'true',
    autofocus: false,
    autoMute: autoplay === 'true',
    id,
    theme,
    recommendations: false,
    sharing: false,
    title: false,
    ylePlayer: true,
    language: playerLang,
  }

  useEffect(() => {
    const element = playerEl.current
    const destroyFn = () => {
      const destroy = player.current?.destroy
      if (destroy) {
        player.current.destroy()
      }

      player.current = undefined
      while (element.firstChild) {
        element.removeChild(element.firstChild)
      }
    }

    // If the element has not been rendered yet, the player will crash.
    // Prevent rendering until all data is in place
    if (!element || !id) {
      return destroyFn
    }

    // Prevent re-rendering
    if (rendering || renderedId === id) {
      return destroyFn
    }

    setRendering(true)

    for (const videoProp of Object.keys(videoProperties)) {
      if (Object.prototype.hasOwnProperty.call(videoProperties, videoProp)) {
        element.setAttribute(`data-${videoProp.toLowerCase()}`, videoProperties[videoProp])
      }
    }

    if (yleTehtavaApp && yleTehtavaApp.ylePlayer && yleTehtavaApp.ylePlayer.embed) {
      // Allow parent site to embed the player.
      yleTehtavaApp.ylePlayer.embed(element, id, { autoplay: autoplay === 'true' })
      setRendering(false)
    } else if (ylePlayer && ylePlayer.render) {
      const params = {
        element,
        props: videoProperties,
      }

      if (!renderedId) {
        window.ylePlayer.render(params).then(pl => {
          player.current = pl
          setRendering(false)
        })
      } else {
        // Content ID has changed: update the player.
        player.current.update({ programId: id, autoplay: videoProperties.autoplay })
        setRendering(false)
      }
    } else {
      // Yle Player v1 and v2 auto embed.
      // https://github.com/Yleisradio/player-static/wiki/Player-embed-instructions#auto-embed
      // http://chili.yle.fi/confluence/pages/viewpage.action?pageId=36995969#Playerinlis%C3%A4%C3%A4minensivulle-K%C3%A4ytt%C3%B6%C3%B6notto
      // We assume the player js script is still loading and will auto-embed
      // the element when ready.
      element.className += ' yle_areena_player'
      element.setAttribute('data-id', id)
      setRendering(false)
    }

    setRenderedId(id)
    return destroyFn
  }, [id, playerEl, autoplay, theme])

  return <div className="yo-areena-player" data-testid="yle-media-player" ref={playerEl} />
}

export default YleMediaPlayer
